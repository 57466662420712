import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Icon } from 'antd'
import styled from 'styled-components'

const Logo = styled.img`
  padding: 10% 6% 10% 10%;
`

const { Sider } = Layout

export const SiderMain = () => {
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => { /*console.log(broken)*/ }}
      onCollapse={(collapsed, type) => { /*console.log(collapsed, type)*/ }}
    >
      <div className="logo" >
        <Logo src={require('./../assets/images/logo.png')} alt='logo' width='90%' />
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
        <Menu.Item key="1">
          <Link to='/main' >
            <Icon type="user" />
            <span className="nav-text">Produtos</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to='/main/servers' >
            <Icon type="user" />
            <span className="nav-text">Servers</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to='/main/accounts' >
            <Icon type="user" />
            <span className="nav-text">Accounts</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default SiderMain