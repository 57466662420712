import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class AccountAdd extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false,
      servers: '',
      products: ''
    }
    this.handleAddAccount = this.handleAddAccount.bind(this)
  }

  componentDidMount() {
    axios.get(window.baseURL + '/servers/').then(response => {
      this.setState({servers: response.data})
    }).catch(e => {
      console.log(e)
    })

    axios.get(window.baseURL + '/products/').then(response => {
      this.setState({products: response.data})
    }).catch(e => {
      console.log(e)
    })
  }

  handleAddAccount (e) {
    e.preventDefault()
    axios.post(window.baseURL + '/accounts/addAccount/', {
        accName:this.refs.accName.value,
        accStream:this.refs.accStream.value,
        prodID:this.refs.product.value,
        servID:this.refs.server.value,
     }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
      if (response.data === 'ok') {
        this.setState({ redirect: true })
      } 
    }).catch(e => {
      console.log(e)
    })
  }

  renderServerOption (server) {
    return <option key={server.id} value={server.id}>{server.servName}</option>
  }

  renderProductOption (product) {
    return <option key={product.id} value={product.id}>{product.prodName}</option>
  }

  renderAddForm () {
    return (
        <div>
          <Title title={'Add Server'} />
          <div className='row'>
            <div className='col-md-3'>
              <form className='form-signin' onSubmit={this.handleAddAccount}>
                 <input className='form-control' name="accName" type="text" ref="accName" placeholder="Account Name" required />
                 <input className='form-control' name="accStream" type="text" ref="accStream" placeholder="Stream Name Deixar Vazio para Live" />
                 <select className="form-control" ref="server">
                   <option>Select Server...</option>
                    { this.state.servers.map((server) => this.renderServerOption(server))}
                </select>

                <select className="form-control" ref="product">
                   <option>Select Product...</option>
                    { this.state.products.map((product) => this.renderProductOption(product))}
                </select>

                 <br />
                <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
              </form>
            </div>
          </div>
        </div>
      )
   }

  render () {
    if (this.state.redirect) {
     return <Redirect to='/main/accounts' />
    }
    return this.state.servers === '' || this.state.products === '' ? <div /> : this.renderAddForm()
  }
}

export default AccountAdd
