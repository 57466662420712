import React, { Component } from 'react'
import axios from 'axios'
import { Icon } from 'antd'

import Title from './elements/TitlePages'
import ButtonActions from './elements/ButtonActions'

class Cameras extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cameras: [],
      accountName: '',
      item: '',
      delete: false
    }
  }

  componentDidMount() {
   axios.get(window.baseURL + '/cameras/account/' + this.props.match.params.accountID).then(response => {
      this.setState({cameras: response.data})
    }).catch(e => {
      console.log(e)
    })

    axios.get(window.baseURL + '/accounts/' + this.props.match.params.accountID).then(response => {
      this.setState({accountName: response.data[0].accName})
    }).catch(e => {
      console.log(e)
    })
  }

  refreshAllCameras = async() => {
    const updateCamera = (camName, update) => this.state.cameras.map(cam => {
      if(cam.camName == camName)
         return Object.assign({}, cam, update)
      return cam
    })
    const refreshOne = async(camName) => {
      const nCams = updateCamera(camName, { refreshing: true })
      this.setState({
        cameras: nCams
      })
      await axios.get(window.baseURL +  '/cameras/refreshCam/' + camName + '/' + this.props.match.params.streamName)
      const nCams2 = updateCamera(camName, { refreshing: false })
      this.setState({
        cameras: nCams2
      })
    }
    const promises = this.state.cameras.map(cam => refreshOne(cam.camName))
    await Promise.all(promises)
  }

  deleteCam(id, camName) {
    console.log(camName)
    axios.get(window.baseURL + '/cameras/deleteCam/' + id + '/' + camName + '/' + this.props.match.params.streamName) .then(response => {
      console.log(response)
      //if (response.data === 'ok') {
        axios.get(window.baseURL +  '/cameras/account/' + this.props.match.params.accountID).then(response => {
          this.setState({cameras: response.data, delete: false, item: ''})
        }).catch(e => {
          console.log(e)
        })
      //}
    }).catch(e => {
      console.log(e)
    })
  }

  refreshCam(camName) {
    axios.get(window.baseURL +  '/cameras/refreshCam/' + camName + '/' + this.props.match.params.streamName) .then(response => {
      //console.log(response)
      //if (response.data === 'ok') {
    }).catch(e => {
      console.log(e)
    })
  }

  renderDeleteAction (id, camName) {
    return (
      <span>
        <button className='btn btn-success' onClick={() => this.setState({delete: false, item: ''})}>No</button> |
        <button className='btn btn-danger' onClick={() => this.deleteCam(id, camName)}>Yes</button>
      </span>
    )
  }

  renderBody(camera, key) {
    return(
      <tr key={camera.id}>
        <th scope="row">{key}</th>
        <td>
          { camera.refreshing && <Icon type="loading" /> }
          {camera.camName}<br />
          <i>{camera.endereco}</i>
        </td>
        {/* <td>{camera.endereco}</td> */}
        <td>
          <button className='btn btn-outline-secondary' onClick={() => this.refreshCam(camera.camName)}> <Icon type='reload' /> Reload</button>
          <ButtonActions link={`/main/live-camera/${camera.camName}/${this.props.match.params.streamName}`} action='ver' icon='desktop' color='btn btn-outline-secondary' />
          <ButtonActions link={`/main/edit-camera/${camera.id}/${this.props.match.params.streamName}`} action='editar' icon='edit' color='btn btn-outline-secondary' />
          {this.state.delete === false || this.state.item !==  camera.camName ? <button className='btn btn-outline-secondary' onClick={() => this.setState({delete: true, item: camera.camName})}><Icon type="delete" /></button> : this.renderDeleteAction(camera.id, camera.camName)}
        </td>
      </tr>
    )
  }

  render () {
    return (
      <div>
        <Title title={'Cameras: ' + this.state.accountName} />
        <ButtonActions link={`/main/new-camera/${this.props.match.params.accountID}/${this.props.match.params.streamName}`} action='+ New Camera' color='btn btn-info' />
        <button onClick={this.refreshAllCameras} color='btn btn-info'>Refresh all cameras</button>
        <br /><br />
        <div className='row' >
          <div className='col-md-12'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Camera Stream</th>
                  {/* <th scope="col">Camera URL</th> */}
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                { this.state.cameras.map((camera, key=+1) => this.renderBody(camera, key))  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default Cameras