import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Layout } from 'antd'

const { Footer } = Layout


const TextFooter = styled.h3`
  text-align: center;
`

export const FooterMain = () => {
  return <Footer><TextFooter>We Dev Ideas - {moment().format('YYYY')}</TextFooter></Footer>
}

export default FooterMain
