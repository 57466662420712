/* eslint-disable */
import React, { Component } from 'react'
import axios from 'axios'

class CameraStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cam: ''
    }
  }

  componentDidMount() {
    axios.get(window.baseURL + '/cameras/status/' + this.props.camName).then(response => {
      this.setState({cam: response.data})
    }).catch(e => { 
      console.log(e)
    })
  }

  renderCameraStatus(cam) {
    return(
      <div className={cam.isConnected === true ? 'alert alert-success' : 'alert alert-danger' }>
        <strong>{cam.isConnected === true ? 'Active' : 'Waiting for stream' }</strong>
        <br />
        address: {cam.sourceIp} <br />
        ptzPollingInterval: {cam.ptzPollingInterval} <br />
        ptzPollingIntervalMinimum: {cam.ptzPollingIntervalMinimum}
      </div> 
    )
  }

  render () { return this.state.cam === '' ? <div>Loading</div> : this.renderCameraStatus(this.state.cam) }

}

export default CameraStatus