import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class AccountEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      account: '',
      servers: '',
      products: '',
      redirect: false
    }
    this.handleEditAccount = this.handleEditAccount.bind(this)
  }

  componentDidMount() {
    axios.get(window.baseURL + '/accounts/' + this.props.match.params.accountID).then(response => {
      this.setState({account: response.data})
    }).catch(e => { 
      console.log(e)
    })

    axios.get(window.baseURL + '/servers/').then(response => {
      this.setState({servers: response.data})
    }).catch(e => {
      console.log(e)
    })

    axios.get(window.baseURL + '/products/').then(response => {
      this.setState({products: response.data})
    }).catch(e => {
      console.log(e)
    })
  }

  handleEditAccount (e) {
    e.preventDefault()
    //console.log(this.refs.accName.value, this.refs.server.value, this.refs.product.value)
    axios.post(window.baseURL + '/accounts/editAccount/', {
        accId: this.state.account[0].id,
        accName:this.refs.accName.value,
        accStream: this.refs.accStream.value,
        prodID:this.refs.product.value,
        servID:this.refs.server.value,
     }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
      if (response.data === 'ok') {
        this.setState({ redirect: true })
      } 
    }).catch(e => {
      console.log(e)
    })
  }

  renderServerOption (server) {
    return <option key={server.id} value={server.id}>{server.servName}</option>
  }

  renderProductOption (product) {
    return <option key={product.id} value={product.id}>{product.prodName}</option>
  }


  renderFormEdit () {
    return (
      <div>
        <Title title={'Edit Account'} />
        <div className='row'>
          <div className='col-md-3'>
            <form className='form-signin' onSubmit={this.handleEditAccount}>
                <input className='form-control' name="accName" type="text" ref="accName" placeholder="Account Name" required defaultValue={this.state.account[0].accName} />
                <input className='form-control' name="accStream" type="text" ref="accStream" placeholder="Stream Name" defaultValue={this.state.account[0].acc_stream} />                     
                <select className="form-control" ref="server">
                  <option value={this.state.account[0].servers_id}>{this.state.account[0].servName}</option>
                  { this.state.servers.map((server) => this.renderServerOption(server))}
                </select>

                <select className="form-control" ref="product">
                  <option value={this.state.account[0].products_id}>{this.state.account[0].prodName}</option>
                  { this.state.products.map((product) => this.renderProductOption(product))}
                </select>
            
               <br />
               <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
            </form>
         </div>
        </div>
      </div>
    )
  }
 
  render () {
    if (this.state.redirect) {
     return <Redirect to='/main/accounts' />
    }
    return this.state.account === '' || this.state.servers === '' || this.state.products === '' ? <div /> : this.renderFormEdit() 
  }
}

export default AccountEdit
