import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { Layout, Alert } from 'antd'

import Footer from './components/Footer'

const {
  Header, Content,
} = Layout

const DivForm = styled.div`
 padding: 5% 8% 5% 1%;
`


class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      redirect: false
    }
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
  }

  componentDidMount () {
    if(localStorage.getItem('token') !== null) {
      this.setState({redirect: true})
    }
  }
  
  handleSubmitForm(e) {
    e.preventDefault()
    axios.post(window.baseURL + '/user/', {
      email:this.refs.email.value,
      pass:this.refs.pass.value
    }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
       console.log(response)
       if (response.data.message === 'ok') {
        localStorage.setItem("token", response.data.token);
         this.setState({redirect: true})
       } else {
          this.setState({error: response.data})
       }
    }).catch(e => {
      console.log(e)
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/main' />
    }
    return (
      <Layout>
      <Header></Header>
        <Content>
        <div className='row'>
          <DivForm className='offset-md-5 col-md-3'>
            <form className='form-signin' onSubmit={this.handleSubmitForm} >
              <input className='form-control' name="email" type="email" ref="email" placeholder="Email" required />
              <input className='form-control' name="pass" type="password" ref="pass" placeholder="Senha" required />
              <button className='btn btn-lg btn-primary btn-block' type='submit'>Entrar</button>
            </form>
            {this.state.error !== '' ? <Alert message={this.state.error} type="error" /> : <div />}
          </DivForm>
        </div>
        </Content>
      <Footer />
    </Layout>
    );
  }
}

export default App;
