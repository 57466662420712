/* eslint-disable */
import React, { Component } from 'react'
import Player from './elements/Player'
import CameraStatus from './elements/CameraStatus'

class CameraLive extends Component {
  render () {
    return(
      <div className="video">
        <Player sourcePath={'http://robin.stream.wedevideas.com/' + this.props.match.params.streamName + '/' + this.props.match.params.camName + '.stream/index.m3u8'} className="embed-responsive-item"/>
        <br /><br />
        <CameraStatus camName={this.props.match.params.camName} />
      </div>
    )
  }
}

export default CameraLive