import React, { Component } from 'react'
import axios from 'axios'
import  { Icon } from 'antd'
import Title from './elements/TitlePages'
import ButtonActions from './elements/ButtonActions'

class Accounts extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accounts: [],
      item: '',
      delete: false
    }
  }

  componentDidMount() {
    axios.get(window.baseURL +  '/accounts/').then(response => {
      this.setState({accounts: response.data})
    }).catch(e => {
      console.log(e)
    })
  }

  deleteAcc(id) {
    axios.get(window.baseURL +  '/accounts/deleteAcc/' + id).then(response => {
      if (response.data === 'ok') {
        axios.get(window.baseURL +  '/accounts/').then(response => {
          this.setState({accounts: response.data, delete: false, item: ''})
        }).catch(e => {
          console.log(e)
        })
      }
    }).catch(e => {
      console.log(e)
    })
  }

  renderDeleteAction (id) {
    return (
      <span>
        <button className='btn btn-success' onClick={() => this.setState({delete: false, item: ''})}>No</button> |
        <button className='btn btn-danger' onClick={() => this.deleteAcc(id)}>Yes</button>
      </span>
    )
  }

  renderBody(account, key) {
    console.log(account)
    let stream = 'live'
    if (account.acc_stream !== '') {
      stream = account.acc_stream
    }
    return(
      <tr key={key}>
        <th scope="row">{key}</th>
        <td>{account.accName} <br />
              {account.prodName}
        </td>
        {/*
          <td>{account.servName}</td>
         */}
        <td>
          <ButtonActions link={`/main/cameras/${account.id}/${stream}`} action='' icon='video-camera' color='btn btn-outline-secondary' />
          <ButtonActions link={`/main/edit-account/${account.id}`} action='' icon='edit' color='btn btn-outline-secondary' />
          {this.state.delete === false || this.state.item !== account.accName ? <button className='btn btn-outline-secondary' onClick={() => this.setState({delete: true, item:account.accName})}><Icon type="delete" /></button> : this.renderDeleteAction(account.id)}
        </td>
      </tr>
    )
  }

  render () {
    let key = 0
    return (
      <div>
        <Title title={'Accounts'} />
        <ButtonActions link='/main/new-account' action='+ New Account' color='btn btn-info' />
        <br /><br />
        <div className='row' >
          <div className='col-md-9'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Account Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.state.accounts.map((account, key=+1) => this.renderBody(account, key)) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default Accounts