import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'antd'

export const TitlePages = ({action, icon, link, color}) => {
  return (
    <Link to={link}><button type='button' className={color}><Icon type={icon} /> { action !== '' ? action : ''}</button></Link>
  )
}
export default TitlePages
