import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class CameraEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      camera: '',
      redirect: false
    }
    this.handleEditCamera = this.handleEditCamera.bind(this)
  }

  componentDidMount() {
    axios.get(window.baseURL + '/cameras/' + this.props.match.params.cameraID).then(response => {
      this.setState({camera: response.data})
    }).catch(e => { 
      console.log(e)
    })
  }

  handleEditCamera (e) {
    e.preventDefault()
    axios.post(window.baseURL + '/cameras/editCamera/', {
        camName: this.refs.camName.value,
        streamName: this.props.match.params.streamName,
        endereco:this.refs.endereco.value,
        camID:this.state.camera[0].id,
     }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
       //console.log(response)
       this.setState({ redirect: true })
      /*if (response.data === 'ok') {
        this.setState({ redirect: true })
      } */
    }).catch(e => {
      console.log(e)
    })
  }

  renderFormEdit () {
    return (
      <div>
        <Title title={'Edit Camera'} />
        <div className='row'>
          <div className='col-md-9'>
            <form className='form-signin' onSubmit={this.handleEditCamera}>
               <input className='form-control' name="camName" type="text" ref="camName" placeholder="Cam Name" required defaultValue={this.state.camera[0].camName}  readOnly />
               <input className='form-control' name="endereco" type="text" ref="endereco" placeholder="Endereco" required defaultValue={this.state.camera[0].endereco} />     
               <br />
               <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
            </form>
         </div>
        </div>
      </div>
    )
  }
 
  render () {
    if (this.state.redirect) {
     return <Redirect to={`/main/cameras/${this.state.camera[0].accounts_id}/${this.props.match.params.streamName}`} />
    }
    return this.state.camera === ''  ? <div /> : this.renderFormEdit() 
  }
}

export default CameraEdit
