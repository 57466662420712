import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

const { Header } = Layout

const UserInfo = styled.div`
  padding-right: 1%;
  float: right;
`

const User = styled.p`
  color: white;
  font-size: 15px;
`

const Logout = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline
  }
`


export const HeaderMain = ({logout, email}) => {
  return ( 
    <Header>
      <UserInfo>
        <User>{email} | <Logout onClick={logout}>Sair</Logout></User>
      </UserInfo>
    </Header>

  )
}

export default HeaderMain
