import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class CameraAdd extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false,
    }
    this.handleAddCamera = this.handleAddCamera.bind(this)
  }

  handleAddCamera (e) {
    e.preventDefault()
    let cameras = this.refs.cameras.value
    let camera = cameras.split('\n')
    camera.forEach(c => {
      //console.log(c)
      if (c !== '') {
        let cam = c.split(';')
        axios.post(window.baseURL + '/cameras/addCamera/', {
          camName:cam[0],
          streamName: this.props.match.params.streamName,
          endereco:cam[1],
          accID:this.props.match.params.accountID,
        }, {headers: { 'Content-Type': 'application/json'}}).then(response => {
          this.setState({ redirect: true }) 
        }).catch(e => {
          console.log(e)
        })
      }
    })
  }

  render () {
    if (this.state.redirect) {
     return <Redirect to={`/main/cameras/${this.props.match.params.accountID}/${this.props.match.params.streamName}`} />
    }
    return (
        <div>
          <Title title={'Add Camera'} />
          <div className='row'>
            <div className='col-md-9'>
              <form className='form-signin' onSubmit={this.handleAddCamera}>
                 
                 <textarea className="form-control" rows="5" id="cameras" ref="cameras" required /> 
                 <br />
                <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
              </form>
            </div>
          </div>
        </div>
      )
   
  }
}

export default CameraAdd
