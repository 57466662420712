import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class ServerEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      server: '',
      redirect: false
    }
    this.handleEditServer = this.handleEditServer.bind(this)
  }

  componentDidMount() {
    axios.get(window.baseURL + '/servers/' + this.props.match.params.servID).then(response => {
      this.setState({server: response.data})
    }).catch(e => { 
      console.log(e)
    })
  }

  handleEditServer (e) {
    e.preventDefault()
    axios.post(window.baseURL + '/servers/editServ/', {
        servId: this.state.server[0].id,
        servName:this.refs.servName.value,
        ip:this.refs.ip.value,
        dominio:this.refs.dominio.value,
     }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
      if (response.data === 'ok') {
        this.setState({ redirect: true })
      } 
    }).catch(e => {
      console.log(e)
    })
  }

  renderFormEdit () {
    return (
      <div>
        <Title title={'Edit Server'} />
        <div className='row'>
          <div className='col-md-3'>
            <form className='form-signin' onSubmit={this.handleEditServer}>
               <input className='form-control' name="servName" type="text" ref="servName" placeholder="Server Name" defaultValue={this.state.server[0].servName} required />
               <input className='form-control' name="ip" type="text" ref="ip" placeholder="ip" defaultValue={this.state.server[0].ip} required />
               <input className='form-control' name="dominio" type="text" ref="dominio" placeholder="Dominio" defaultValue={this.state.server[0].dominio} required />
               <br />
               <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
            </form>
         </div>
        </div>
      </div>
    )
  }
 
  render () {
    if (this.state.redirect) {
     return <Redirect to='/main/servers' />
    }
    return this.state.server === '' ? <div /> : this.renderFormEdit() 
  }
}

export default ServerEdit
