import React, { Component } from 'react'
import { Layout } from 'antd'
import { Route, Redirect } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'
import Sider from './components/Sider'

import Products from './Products'
import ProductAdd from './components/ProductAdd'
import ProductEdit from './components/ProductEdit'
import Servers from './Servers'
import ServerAdd from './components/ServerAdd'
import ServerEdit from './components/ServerEdit'
import Accounts from './Accounts'
import AccountAdd from './components/AccountAdd'
import AccountEdit from './components/AccountEdit'
import Cameras from './Cameras'
import CameraAdd from './components/CameraAdd'
import CameraEdit from './components/CameraEdit'
import CameraLive from './CameraLive'

var jwt = require('jsonwebtoken')


const { Content } = Layout

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false,
      email: ''
    }
  }

  componentDidMount () {
    if(localStorage.getItem('token') === null) {
      this.setState({redirect: true})
    } else {
      let decoded = jwt.decode(localStorage.getItem('token'))
      this.setState({email: decoded.email})
    }
  }

  logout() {
    localStorage.removeItem('token')
    this.setState({redirect: true})
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />
    } 
    return (
      <Layout>
        <Sider />
        <Layout>
          <Header logout={() => this.logout()} email={this.state.email} />
          <Content  style={{ margin: '16px 16px' }}>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
              <Route exact path='/main' name='products' component={Products} />
              <Route exact path='/main/new-product' component={ProductAdd} />
              <Route exact path='/main/edit-product/:prodID' component={ProductEdit} />
              <Route exact path='/main/servers' name='servers' component={Servers} />
              <Route exact path='/main/new-server' component={ServerAdd} />
              <Route exact path='/main/edit-server/:servID' component={ServerEdit} />
              <Route exact path='/main/accounts' name='accounts' component={Accounts} />
              <Route exact path='/main/new-account' name='accounts' component={AccountAdd} />
              <Route exact path='/main/edit-account/:accountID' name='accounts' component={AccountEdit} />
              <Route exact path='/main/cameras/:accountID/:streamName' name='cameras' component={Cameras} />
              <Route exact path='/main/new-camera/:accountID/:streamName' name='cameras' component={CameraAdd} />
              <Route exact path='/main/edit-camera/:cameraID/:streamName' name='cameras' component={CameraEdit} />
              <Route exact path='/main/live-camera/:camName/:streamName' name='cameras' component={CameraLive} />
            </div>
          </Content>
          <Footer />
        </Layout>
      </Layout>
    );
  }
}

export default Home
