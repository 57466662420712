import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class ServerAdd extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false
    }
    this.handleAddServer = this.handleAddServer.bind(this)
  }

  handleAddServer (e) {
    e.preventDefault()
    axios.post(window.baseURL + '/servers/addServer/', {
        servName:this.refs.servName.value,
        ip:this.refs.ip.value,
        dominio:this.refs.dominio.value,
     }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
      if (response.data === 'ok') {
        this.setState({ redirect: true })
      } 
    }).catch(e => {
      console.log(e)
    })
  }

  render () {
    if (this.state.redirect) {
     return <Redirect to='/main/servers' />
    }
    return (
      <div>
        <Title title={'Add Server'} />
        <div className='row'>
          <div className='col-md-3'>
            <form className='form-signin' onSubmit={this.handleAddServer}>
               <input className='form-control' name="servName" type="text" ref="servName" placeholder="Server Name" required />
               <input className='form-control' name="ip" type="text" ref="ip" placeholder="ip" required />
               <input className='form-control' name="dominio" type="text" ref="dominio" placeholder="Dominio" required />
               <br />
              <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ServerAdd
