import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import axios from 'axios'

import App from './App'
import Main from './Main'
import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.css'

//http://campanel.stream.wedevideas.com/api
//http://localhost:8081/api
window.baseURL = 'http://campanel.stream.wedevideas.com/api'

//prod - auth / dev - authorization
if(localStorage.getItem('token')) {
  axios.defaults.headers.common['auth'] = localStorage.getItem('token');
}

class Index extends React.Component {
    render () {
      return (
        <Router>
          <div>
            <Route exact path='/' name='login' component={App} />
            <Route path='/main' component={Main} />
          </div>
        </Router>
      )
    }
  }
  

ReactDOM.render(<Index />, document.getElementById('root'))
serviceWorker.unregister();
