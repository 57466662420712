import React, { Component } from 'react'
import axios from 'axios'
import  { Icon } from 'antd'
import Title from './elements/TitlePages'
import ButtonActions from './elements/ButtonActions'

class Products extends Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      item: '',
      delete: false
    }
  }

  componentDidMount() {
    axios.get(window.baseURL + '/products/').then(response => {
      this.setState({products: response.data})
    }).catch(e => {
      console.log(e)
    })
  }

  deleteProd(id) {
    axios.get(window.baseURL + '/products/deleteProd/' + id).then(response => {
      if (response.data === 'ok') {
        axios.get(window.baseURL + '/products/').then(response => {
          this.setState({products: response.data, delete: false, item: ''})
        }).catch(e => {
          console.log(e)
        })
      }
    }).catch(e => {
      console.log(e)
    })
  }

  renderDeleteAction (id) {
    return (
      <span>
        <button className='btn btn-success' onClick={() => this.setState({delete: false, item: ''})}>No</button> |
        <button className='btn btn-danger' onClick={() => this.deleteProd(id)}>Yes</button>
      </span>
    )
  }

  renderBody(product, key) {  
    return(
      <tr key={key}>
        <th scope="row">{key}</th>
        <td>{product.prodName}</td>
        <td>
          <ButtonActions link={`/main/edit-product/${product.id}`} action='' icon='edit' color='btn btn-outline-secondary' />
          {this.state.delete === false || this.state.item !== product.prodName ? <button className='btn btn-outline-secondary' onClick={() => this.setState({delete: true, item: product.prodName})}><Icon type="delete" /></button> : this.renderDeleteAction(product.id)}
        </td>
      </tr>
    )
  }

  render () {
    let key = 0
    return (
      <div>
        <Title title={'Products'} />
        <ButtonActions link='/main/new-product' action='+ New Product' color='btn btn-info  ' />
        <br /><br />
        <div className='row' >
          <div className='col-md-9'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Product</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.products !== [] && this.state.products.map((product, key=+1) => this.renderBody(product, key))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default Products