import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import Title from './../elements/TitlePages'

class ProductAdd extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false
    }
    this.handleAddProduct = this.handleAddProduct.bind(this)
  }

  handleAddProduct (e) {
    e.preventDefault()
    axios.post(window.baseURL + '/products/addProd/', {
      prodName:this.refs.prodName.value,
     }, {headers: { 'Content-Type': 'application/json' }}).then(response => {
      if (response.data === 'ok') {
        this.setState({ redirect: true })
      } 
    }).catch(e => {
      console.log(e)
    })
  }

  render () {
    if (this.state.redirect) {
     return <Redirect to='/main' />
    }
    return (
      <div>
        <Title title={'Add Product'} />
        <div className='row'>
          <div className='col-md-3'>
            <form className='form-signin' onSubmit={this.handleAddProduct} >
              <input className='form-control' name="prodName" type="text" ref="prodName" placeholder="Product Name" required />
               <br />
              <button className='btn btn-success' type='submit' width='80%'>Salvar</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductAdd
